<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}用户服务协议</h3>
      <p style="text-align: right">生效时间：2023年【11】月【09】日</p>
      <p style="text-align: right">版本号：2023v1.0</p>
      <section>
        <h4>提示条款</h4>
        <p>
          感谢您使用{{ appName }}小程序！为使用成都开心音符科技有限公司（以下简称"我方"）运营的{{
            appName
          }}客户端软件提供的相关服务（以下简称"我方服务")，您应当阅读并遵守《用户服务协议》（以下简称"本协议")。<span
            class="bold"
            >建议您仔细阅读本协议的全部内容，尤其是以加粗形式展示的，与您的权益（可能）存在重大关系的条款（包括相关约定免除或者限制责任的条款、法律适用和争议解决等条款），请您务必审慎阅读、充分理解个条款内容。</span
          >各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。您点击同意或您使用我方服务（"使用"行为指包括但不限于下载、安装、启动、浏览、注册、登录等行为中的一种或多种，下同），即表示您已阅读并同意签署本协议所有内容，本协议即在您与我方之间产生法律效力，成为对双方均具有约束力的法律文件。<span
            class="bold"
            >如您不同意我方不时对本协议的修改或补充内容，您应放弃注册、停止使用或主动取消本服务。</span
          >
        </p>

        <h4>本《服务协议》将帮助您了解以下内容：</h4>
        <h5>1．定义</h5>
        <h5>2．协议范围</h5>
        <h5>3．账号注册与使用</h5>
        <h5>4．我方服务及规则</h5>
        <h5>5．特别约定</h5>
        <h5>6．协议的变更</h5>
        <h5>7．法律适用、管辖与其他</h5>
      </section>
      <section>
        <h4>1．定义</h4>
        <p>
          1.1．我方：是指成都开心音符科技有限公司及其相关服务可能存在的运营关联单位，但不包括第三方功能及相关服务的实际提供方。
        </p>
        <p>1.2．我方服务：{{ appName }}向您提供的各项产品及/或服务的网站、客户端、小程序以及随技术发展出现的新形态。</p>
        <p>
          1.3．服务规则：包括在所有我方服务内已经发布及后续发布的全部规则、解读、公告等内容以及各平台在频道、活动页面、帮助中心等发布的各类规则、实施细则、产品说明、公告等。
        </p>
        <p>1.4．用户：包括使用我方服务的组织、个人，本协议中可称为“用户”或“您”。</p>
      </section>

      <section>
        <h4>2．协议范围</h4>
        <p>
          2.1.【签约主体】本协议由您与我方共同缔结，本协议对您与我方均具有合同效力。本协议项下，我方可能根据我方服务的业务调整而发生变更，变更后的服务经营者与您共同履行本协议并向您提供服务，我方的变更不会影响您本协议项下的权益。
        </p>
        <p>2.2.【补充协议】我方服务规则为本协议的补充协议，与本协议不可分割并具有同等法律效力。</p>
        <p>
          2.3.【其他】本协议为统一适用的一般性用户服务条款。针对我们的某些特定服务，我们还将制定特定服务协议，以便更具体地向您阐明该等产品及/或服务的服务内容、服务规则等内容，您应在充分阅读并同意特定服务协议的全部内容后再使用该特定服务。
        </p>
      </section>

      <section>
        <h4>3．账号注册与使用</h4>
        <h5>3.1．用户资格</h5>
        <p>
          您确认，在您开始使用/注册程序使用我方服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。特别地，如果您是未成年人，请在您的监护人的同意和指导下访问和/或使用我方服务。我方已提供“未成年人模式”，可在“我的”-“设置”页面内选择开启。
        </p>
        <h5>3.2．账号说明：</h5>
        <p>
          3.2.1【账号获得】当您按照注册页面提示填写信息或授权第三方账号登录，阅读并同意本协议，您可获得我方账号（"快音
          写歌账号"）并成为我方用户。
          <span
            >为落实国家法律法规的有关实名认证的要求，保障您的账号安全，您在注册时需要向我们提供真实的手机号码；此外，为了向您提供已有或未来上线的部分功能，我方可能还会严格依照法律法规要求向您收集其他个人信息。</span
          >
        </p>
        <p>
          3.2.2【账号使用】您有权授权第三方账号或者使用您的手机号码等方式登录我方服务。由于您的账号关联您的个人信息及我方服务商业信息，您的账号仅限您本人使用。<span
            class="bold"
            >未经我方同意，您直接或间接授权第三方使用您账号行为无效。如我方根据服务规则中约定的违约认定程序及标准判断您账号的使用可能危及您的账号安全及/或我方服务信息安全的，我方有权对用户行为及应适用的规则进行认定，并据此处理，暂停或停止提供相应的服务。</span
          >
        </p>
      </section>

      <section>
        <h4>4．我方服务及规则</h4>
        <h5>4.1．我方服务</h5>
        <p>
          4.1.1服务范围：{{
            appName
          }}可为您提供基于生成算法的音乐创作服务。您可以通过一段话介绍您想创作的歌曲，我们将基于您的创想，生成相应的歌曲。此外，您也可以使用我们的歌手生成服务，在此过程中，您可能需要根据界面指引，录制几段清唱音频，我们需要从您的清唱录音音频文件中提取您的声纹信息，以生成基于您音色的歌手声音。以上您向我们输入的内容与我们生成的内容，统称为"您的内容"。
        </p>
        <p>
          4.1.2
          服务费用：本服务目前仅针对个人用户，本服务中可能包含一些收费服务（如歌手功能），采用先收费后服务的方式，您支付的费用是您所购买服务所对应的网络服务价格，而非预付款或者存款、定金、储蓄卡等性质。您一经使用我方服务后不可转让或退款（如因我方服务存在重大瑕疵导致您完全无法使用等违约情形、本协议另有约定、法律法规要求必须退款的或经我们判断后认为可以退款等除外）。我方服务的收费标准由我方根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的服务宣传及支付页面向您展示；若您在购买时，相关收费方式发生变化的，以我方实际接受的收费方式为准；相关服务的价格发生了调整的，应以我方公示的现时有效的价格为准（但我方与您另有约定的情形除外）。
        </p>
        <p>4.1.3知识产权：</p>
        <p>
          (1）我方知识产权：除非另有约定或我方另行声明，本服务所使用的我方服务提供的内容（用户依法享有权利的除外）、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档等）、我方的Logo、"{{
            appName
          }}小程序"等文字、图形及其组合，以及我方的其他标识、徵记、产品和服务名称的知识产权及相关权利，均归我方或我方关联公司所有。未经我方许可，任何人不得擅自使用（包括但不限于复制、传播、展示、镜像、上载、下载、修改、出租等）。
        </p>
        <p class="bold">
          (2）除非法律另有规定或双方另有约定的，您在使用本服务时生成内容的所有权归您所有（涉及到原本归属于第三方知识产权的内容除外），您授予我方非排他的、无地域限制的、永久的、免费的、可多层次再许可的进行使用的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等）。我方可以授权他人使用，或通过算法模型开发训练等方式开发该等全部或部分内容。
        </p>
        <p class="bold">
          (3）您同意，授权我方、我方服务的其他用户使用您在使用本服务时提交的所有文本类输入数据（包括输入关键词、选择曲风等指令）以及您在使用本服务时的生成内容，我方、我方服务的其他用户有权以改编、编辑等二次创作方式形成新的文本/音频内容，并通过我方服务平台发布、下载、传播。
        </p>
        <p class="bold">
          (4）您保证对于您的上传内容拥有相应的合法权利或已取得他人合法授权并有权用于本服务；否则，造成的一切后果及损失由您自行承担。在此过程中，您所上传的音频将仅被用于本服务使用，我们仅提供音频处理服务，不会提取识别信息，不会用于识别用途，服务完成后，系统将自动删除上述信息，不予留存。
        </p>
        <p>
          4.1.4
          禁止的内容：您理解并保证您的内容及您的账号名称等信息应当遵守宪法、法律和行政法规，坚持为人民服务、为社会主义服务的方向，坚持正确舆论导向，符合社会主义核心价值观，促进形成积极健康、向上向善的网络文化。不得侵犯国家安全和社会公共利益，不得侵犯公民、法人和其他组织的合法权益。若发现您的内容及您的账号名称等信息中包含法律、行政法规禁止发布或者生成的信息的，或含有以下内容的，我方有权并将立即停止生成或传输该信息，采取消除等处置措施，防止信息扩散，保存有关记录，并向有关主管部门报告。您理解并保证不得利用本服务提供的资源和信息自行或者帮助他人生成、获取、传播以下信息:
        </p>
        <h5>(1）反对宪法确定的基本原则的；</h5>
        <h5>(2）危害国家统一、主权和领土完整的；</h5>
        <h5>(3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</h5>
        <h5>(4）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的;</h5>
        <h5>(5）宣扬邪教、迷信的；</h5>
        <h5>(6）扰乱社会秩序，破坏社会稳定的；</h5>
        <h5>(7）传播虚假信息的；</h5>
        <h5>(8）宣扬恐怖主义、极端主义的；</h5>
        <h5>(9）诱导他人（尤其是未成年人）违法犯罪和渲染暴力、色情、赌博活动的；</h5>
        <h5>(10）侮辱或者诽谤他人，侵害公民个人名誉、肖像、隐私、知识产权和其他合法权益的；</h5>
        <h5>(11）危害社会公德，损害民族优秀文化传统的；</h5>
        <h5>(12）有关法律、行政法规和国家规定禁止的其他内容。</h5>
        <p>
          4.1.5禁止的行为：本服务可帮助您探索新主题、学习新知识，还可激发您的创造力。不过，您使用本服务的方式和创作生成的生成内容必须符合相关法律法规的规定和相关协议的约定。您理解并保证，您在使用本服务时：
          您不得使用生成内容（包括但不限于图片等形式）进行任何诽谤、色情或其他违法、违规及任何违反本条款约定的行为，不得将生成内容用于任何侵犯第三方的人格权、名誉权以及其他合法权益（包括但不限于著作权、商标权或其他专有权利等）的用途。如因您违反上述约定，我方有权依法依约对您采取警示、限制功能、暂停或终止服务、关闭账号等处置措施，并且如因您违反前述约定使用本服务导致任何第三方合法权益受到损害的，您将依法承担相应责任。我方因此产生损失的，有权向您追责。
        </p>
        <p>
          （1）您的输入内容不得侵犯任何人的知识产权、肖像权、名誉权、荣誉权、姓名权、隐私权、个人信息权益等合法权益，不涉及任何国家秘密、商业秘密、重要数据或其他可能会对国家安全或者公共利益造成不利影响的数据；
        </p>
        <p>（2）您不得使用生成内容进行任何诽谤、色情或其他违法、违规及任何违反本条款约定的行为；</p>
        <p>
          （3）不得将生成内容用于任何侵犯第三方的人格权、名誉权以及其他合法权益（包括但不限于著作权、商标权或其他专有权利等）的用途；
        </p>
        <p>（4）您不得以任何方式删除、篡改、隐匿我方服务中的人工智能生成标识或深度合成内容显著标识。</p>
        <p class="bold">
          如因您违反上述约定，我方有权依法依约对您采取警示、限制功能、暂停或终止服务、关闭账号等处置措施，并且如因您违反前述约定使用本服务导致任何第三方合法权益受到损害的，您将依法承担相应责任。我方因此产生损失的，有权向您追责。
        </p>

        <p>4.1.6 使用规范：</p>
        <p>如果您对外发布或传播我方服务生成的内容，您应当：</p>
        <p>（1）主动核查输出内容的真实性、准确性，避免传播虚假信息；</p>
        <p>（2）以显著方式标明该输出内容系由人工智能生成，以向公众提示内容合成的情况。</p>
        <p>4.1.7个人信息保护：</p>
        <p>(1）我方会按照适用的法律法规和任何相关的保密安全条款使用您的个人信息。</p>
        <p>
          (2）我方严格遵守法律法规保护您的个人信息，在合理的安全水平内使用各种安全保护措施以保障您的个人信息的安全，以防止信息的泄露、违法收集和使用。
        </p>
        <p>
          (3）如果您对个人信息保护问题有任何疑问或投诉，您可以联系我方。具体的保护措施及联系方式请参阅
          <a :href="privacyUrl">《隐私政策》</a>。
        </p>
        <p>4.1.8未成年人保护：</p>
        <p>
          (1）我方服务主要面向成人。我方没有专门针对儿童提供产品或服务，我们主要依靠用户是否选择“青少年模式”来判断用户是否属于儿童。我方已提供“未成年人模式”，可在“我的”-“设置”页面内选择开启。如果您是未满18周岁的未成年人，您应在您的监护人监护、指导下并获得监护人同意的情况下，认真阅读并同意本规则后，方可使用本服务。我方充分理解保护未成年人的重要性，并会根据法律法规的要求及行业主流实践采取相应的保护措施。与此同时，请监护人合理引导和约束未成年人用户对本服务的使用，共同营造良好网络环境，帮助未成年人养成良好上网习惯，避免过度依赖或者沉迷本服务。
        </p>
        <p>
          (2）我方重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应在取得监护人的同意以及在监护人指导下正确使用本服务。
        </p>
        <p>(3）未成年人用户在使用本服务时，要注意区分网络与现实的区别，避免沉迷于网络，养成良好上网习惯。</p>
        <p>
          (4）监护人应指导未成年人用户注意网络安全问题，防患于未然。未成年人用户及其监护人理解并确认，如您违反法律法规、本条款，则您及您的监护人应依照法律规定承担因此而导致或可能导致的全部法律责任。
        </p>
        <p>
          (5）对于未成年人用户的隐私保护，我方将严格按照我方公布的<a :href="privacyUrl">《隐私政策》</a>中阐述予以执行。
        </p>
        <p>4.1.9免责声明：</p>
        <p class="bold">
          您确认并知悉当前服务生成的所有内容即生成内容都是由人工智能模型生成。我方对其生成的内容的准确性、完整性和功能性不做任何保证，并且其生成的内容不代表我方的态度或观点。您需要自行斟酌是否依赖、发布或以其他方式使用本服务提供的内容和信息，并同意自行承担相关的风险。您理解并同意不依赖本服务在医疗、法律、金融或其他专业领域给出的建议或信息。本服务在以上领域提供的任何内容均仅供参考，无法取代合格专业人士的建议。本服务来自于法律法规允许的包括并不限于公开互联网信息积累，并已经过不断的自动及人工敏感数据过滤，但仍不排除其中部分信息可能存在瑕疵，不合理或引发不快之处。如遇此情形的，欢迎并感谢您随时通过我方服务所载方式进行监督。除本规则另有规定外，我方有权根据商业判断对活动页面进行变更、中断或终止。除我方发布的其他规则或与用户签署的其他文件另有规定外，我方不对相应的变更、中断或终止承担任何责任
        </p>
        <h5>
          4.2【广告和商业信息】您知悉并同意，我方在提供服务的过程中，保留以各种方式投放商业广告或其他任何类型的商业信息的权利（包括但不限于在我方服务的任何位置上投放广告）。
        </h5>
      </section>
      <section>
        <h4>5．特别约定</h4>
        <p>
          5.1【不可抗力】我方负责"按现状"和"可得到"的状态向您提供我方服务。我方依法律规定承担相应义务，但对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，黑客活动、计算机病毒、电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果，我方不承担法律有明确规定之外的责任。
        </p>
        <p>
          5.2【域外行为】我方通过中华人民共和国境内的设施控制和提供我方服务，我方不担保控制或提供的服务在其他国家或地区是适当的、可行的，任何在其他司法辖区使用我方服务的用户应自行确保其遵守当地的法律法规，我方在法律有明确规定的情况下承担相应的责任。
        </p>
      </section>
      <section>
        <h4>6．协议的变更</h4>
        <p class="bold">
          鉴于互联网服务的特殊性，我们有权对本协议以及服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务或收费服务。我们会更新本协议，并通过公告或系统消息或其他适当的方式提醒您。但未经您明确同意，我们不会限制您按照本协议所享有的权利。如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用我方服务；如您在变更事项生效后仍继续使用我方服务，则视为您同意已生效的变更事项。
        </p>
      </section>
      <section>
        <h4>7.投诉及公众举报</h4>
        <p>
          7.1投诉及公众举报：如果您认为我方服务涉及侵害您知识产权或其他权利的，或者您发现任何违法信息、不良信息、虚假信息以及违反本协议的使用行为，您可将相关投诉或举报材料发送至该邮箱：happymusic@rings.net.cn，或通过小程序内的举报按钮、客服按钮向我们的工作人员反馈。我们会在收到您的投诉或举报材料后进行处理，包括及时采取停止生成、停止传输、消除等合理处置措施。
        </p>
        <p>7.2反馈时限：我们将在您提出投诉或举报后的【15】个工作日内向您反馈相关情况。</p>
      </section>
      <section>
        <h4>8．法律适用、管辖与其他</h4>
        <p>
          8.1【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
        </p>
        <p class="bold">
          8.2【管辖】您因使用我方服务所产生及与我方服务有关的争议，由我方与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。
        </p>
        <p>
          8.3【条款有效性】本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        appName: '',
        privacyUrl: 'https://landing.happyky.cn/music-lib/privacy-protocol',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (this.$route.query.type && +this.$route.query.type == 2) {
        this.appName = '快乐跟唱';
      } else {
        this.appName = '快音AI写歌';
      }
      document.title = `${this.appName}用户服务协议`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 16px;
  }
  h4 {
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 8px 0;
    font-size: 14px;
  }
  p {
    text-indent: 2em;
    margin: 6px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
